@import "variables";
@import "mixins";

.container-fluid {
  max-width: $screen-max-width;
  margin: 0 auto;
}

html, body {
  background: var(--color-dark-gray);
}

#root {
  background: var(--color-dark-gray);
  min-height: 100vh;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--color-lihgt-gray);

  @include breakpoint-lg {
    font-size: 18px;
  }

  @include breakpoint-md {
    font-size: 16px;
  }

  @include breakpoint-sd {
    font-size: 12px;
  }
}

a {
  text-decoration: none;
}

* {
  font-family: 'Roboto', sans-serif;
}

a:hover {
  text-decoration: none;
}

.flex {
  display: flex !important;
  //width: 100%;
}

.flex-inline {
  display: inline-flex !important;
  //width: 100%;
}

.flex-align-baseline {
  @extend .flex;
  align-items: baseline;
}

.flex-align-center {
  @extend .flex;
  align-items: center;
}

.flex-align-end {
  @extend .flex;
  align-items: end;
}

.flex-justify-end {
  @extend .flex;
  justify-content: end;
}

.flex-justify-center {
  @extend .flex;
  justify-content: center;
}

.flex-space-between {
  @extend .flex;
  justify-content: space-between;
}

.flex-space-around {
  @extend .flex;
  justify-content: space-around;
}


.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-row-center {
  @extend .flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  @extend .flex-column;
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  @extend .flex;
  flex-wrap: wrap;
}


.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.h-50 {
  height: 50%;
}

.h-60 {
  height: 60%;
}

.h-100 {
  height: 100%;
}

.float-right {
  float: right;
}

.transparent {
  opacity: 0;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-end {
  align-self: end;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 1;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.d-inline{
  display: inline;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
//
//*::-webkit-scrollbar {
//  width: 1em;
//}
//
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-white);
  //background: #2F9276;
}

.no-white-space {
  white-space: nowrap;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

.inline-grid {
  display: inline-grid !important;
}

.grid-table-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-table-2-1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid-table-1-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-table-1-3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.grid-table-1-4 {
  display: grid;
  grid-template-columns: 1fr 4fr;
}


.invisible {
  opacity: 0;
  pointer-events: none !important;
  z-index: -1;
};

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-auto {
  overflow: auto;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.relative {
  position: relative;
}

.border-radius-05 {
  border-radius: 1rem;
}

.p-absolute {
  position: absolute;
}

.absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-centered-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-centered-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rotate--45 {
  transform: rotate(-45deg);
}

.filters-placeholder {
  background: var(--color-dark-gray);
  z-index: 1;
  height: 2.75rem;
}

.disabled {
  opacity: 0.35;
  pointer-events: none;
}

.checkbox {
  accent-color: var(--color-success) ;
}
