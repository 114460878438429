@import "src/styles/mixins";

.analytics {
  font-family: sans-serif;

  @include breakpoint-sd {
    margin-top: 2rem;
  }
  
  &__calendars-gap {
    gap: 3vw;
  };

  &__error-section {
    min-height: 500px;
  };
  
  &__section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, auto));
    grid-gap: 10px 5px;
    grid-auto-rows: 2rem;
    animation: fadein 300ms;
    background-color: var(--color-card-gray);
    min-width: 136px;

    &__card-counts {
      background: var(--color-gray);
      justify-content: center;
      max-width: 120px;

      &__diff-indicator {
        filter: brightness(1.7); 
        transform: scaleY(0.7);
      };
    };

    &__card-frequencies {
      background: var(--color-gray);
      justify-content: center;
      max-width: 115px;
    };
  };
}