$base-color: #43BC2F;

.station-fields {
  width: 85%;
  padding-left: 2rem;

  &__label {
    text-align: left;
    margin: 1rem 2rem;
    min-width: 15rem;
    color: var(--color-gray);

    &--input {
      min-width: 5rem;
    }
  }

  &__label-wrapper {
    border-bottom: 1px solid var(--color-gray);
  }
}

.station-settings__recommendations {
  background: var(--color-card-gray);
}

.station-settings__slider {
  appearance: none;
  padding: 0;
  height: 0.1rem;
  background: $base-color;
  border: none;
}

.station-settings__slider::-webkit-slider-thumb {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: $base-color;
  cursor: grab;
  -webkit-appearance: none;
}

.station-settings__slider-units {
  width: 10%;
  color: $base-color !important;
}

.stop-hand-container {
  border: 5px solid var(--color-danger);
}

.dashed-border-danger {
  border: 1px dashed var(--color-danger);
}
