.add-station-component {
  margin-top: 3rem;

  &__input {
    width: 100%;
    max-width: 30rem;
    display: inline-block;
    margin-bottom: 2rem;

    .input {
      font-size: 1.5rem;
      display: inline-block;
      width: 100%;
    }
  }

  &__label {
    min-width: 7rem;
    text-align: left;
  }

  &__select {
    border-bottom: 1px solid var(--color-white-medium-gray);
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    color: var(--color-white-medium-gray);
  }
}
