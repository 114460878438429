@import "src/styles/mixins";

.my-map-component {
  width: 100%;
  height: 600px;

  iframe {
    display: block;
    height: 100%;
    width: 100%;
  }

  &.is-fullsize {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
  }
}

.layer-marker-elescope {
  width: 2rem;
  height: 2rem;

  @include apply-backgrounds;

  &.blinking {
    background: var(--color-danger);
    animation: blink-animation 1s steps(5, start) infinite;
  }
}

.layer-marker {
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding: 0.5rem 0.25rem;
  box-shadow: 0 0 3px 1px var(--color-dark-gray);
  position: relative;
  background: red;
  border-radius: 5px;

  &.blinking {
    animation: blink-animation 1s steps(5, start) infinite;
  }

  @include apply-backgrounds;

  &__multiple-label {
    background: var(--color-primary);
    position: absolute;
    top: 0.15rem;
    right: 0.15rem;
    transform: translate(50%, -50%);
    border-radius: 50%;
    box-shadow: 0 0 5px 1px var(--color-white);
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
  }

  &__inner {
    width: 70%;
    height: 70%;
  }

  &__sign {
    left: -50%;
    top: -50%;
  }
}

.layer-icon {
  width: 3rem;
  align-self: flex-start;
  cursor: pointer;
}

.map {
  &__grid-table {
    span {
      align-self: center;
      margin-bottom: 0.25rem;
    }
  }

  &__tooltip {
    min-width: 250px;

    &__label {
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
    }
  }
}

.multiple-tooltip-item {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.baklans-layer-marker {
  width: 2rem;
  height: 2rem;
  background: rgba(255,255,255, 0.25);
  box-shadow: 0 0 3px 1px var(--color-dark-gray);
  border-radius: 5px
}
