.line-height-1rem {
  line-height: 1rem;
}

.writing-mode-vertical-lr {
  writing-mode: vertical-lr;
}

.writing-mode-vertical-rl {
  writing-mode: vertical-rl;
}
