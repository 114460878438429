.tooltip-overlay {
  max-width: 300px;
  padding: 0;
}

.rc-tooltip {
  position: absolute !important;

  &.is-hint {
    background: var(--color-gray);
    color: var(--color-dark-gray);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
  }
}

.tooltip-text {
  padding: 0.25rem;
  //background: var(--color-dark-gray);
  color: var(--color-lihgt-gray);
  //box-shadow: 0 0 0 0.25px var(--color-gray);
  border-radius: 0.25rem;
}
