

div.customize-select {
  &__option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__control {
    background: transparent;
    border: none;
    border-radius: 0;
    color: var(--color-gray);

    &--is-focused {
      border-color: var(--color-white) !important;
      box-shadow: none;
    }
  }

  svg {
    color: currentColor;

    path {
      stroke: currentColor !important;
    }
  }

  &__menu {
    z-index: 2;
    margin-top: 5px;
    position: absolute;
    background: transparent;
    border-radius: 1rem;
  }

  &__single-value {
    color: var(--color-gray);
  }

  &__menu-list {
    background: var(--color-half-dark-gray);
    border-radius: 1rem;
  }

  &__option {
    color: var(--color-lihgt-gray);
    font-size: 0.8rem;

    &:hover {
      color: var(--color-dark-gray);
    }

    &--is-focused {
      background: var(--color-medium-gray) !important;
      color: var(--color-dark-gray);
    }

    &--is-selected {
      background: var(--color-primary);
      //color: var(--color-dark-gray);
    }
  }
}

.customize-select__indicator {
  padding: 0;
}

.customize-select__indicator-separator {
  width: 0;
  display: none !important;
}

.customize-select__menu-list.customize-select__menu-list {
  background: var(--color-card-gray);
  width: auto;
}
