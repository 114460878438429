.filter-control {
  padding: 0.125rem 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 125ms;
  background: var(--color-card-gray);
  color: var(--color-lihgt-gray);
  min-width: 150px;
  text-align: center;

  &:hover {
    background: var(--color-primary);

    div, span, svg {
      color: var(--color-white);
    }
  }
}
