.mx-auto {
  margin: 0 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.transition-125 {
  transition: all 125ms;
}

.transition-250 {
  transition: all 250ms;
}

.hover-white:hover {
  color: var(--color-white);
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.pointer-events-none {
  pointer-events: none;
}

.dashed-border {
  border: 1px dashed var(--color-gray);
}

.rounded-05 {
  border-radius: 0.5rem;
}

.rounded-1 {
  border-radius: 1rem;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-80 {
  opacity: 0.8;
}

.blinking {
  animation: blink-animation 1s steps(5, start) infinite;
}

.overflow-hidden {
  overflow: hidden;
}

.text-overflow-elipsis {
  text-overflow: ellipsis;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.height-fit-content {
  height: fit-content;
}
