@import "src/styles/mixins";

.inner-tabs-position-right {
  margin-top: -3.5rem;
  right: 0;
  margin-right: 5vw;

  @include breakpoint-lg {
    margin-right: 1vw;
  }

  @include breakpoint-sd {
    right: unset;
    margin-right: unset;
    margin-top: -1.5rem;
    left: 5px;
    margin-left: 1vw;
  }
}