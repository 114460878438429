.elescope-drone-card {
  height: fit-content;
  position: relative;
  overflow: hidden;

  &__label {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
  }
}