.vms-camera-overlay {
  box-shadow: 0 0 3px 1px var(--color-dark-gray-50);
}

.vms-map-tooltip-card {
  width: fit-content;
  background: var(--color-dark);
}

.vms-tooltip-hint {
  //background: var(--color-lihgt-gray);
  border-radius: 50%;

  svg {
    color: var(--color-lihgt-gray);
    transition: all 200ms;

    &:hover {
      color: var(--color-success);
    }
  }

  &.inactive {
    opacity: 0.4;

    svg:hover {
      color: var(--color-lihgt-gray);
      cursor: default;
    }
  }
}

.vms-cameras-to-videowall {
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 3;
  border-radius: 0.25rem;
  padding: 0.5rem;

  background: var(--color-lihgt-gray-90);

  &__item {
    padding: 0.5rem;
  }
}

.vms-preview-arrows {
  top: 68% !important;
}

.vms-cameras-dropdown-overlay {
  .dropdown {
    max-height: 500px;
    overflow: auto;
  }
}

.inset-0 {
  inset: 0;
}

.vms-camera-name-wrapper {
  min-width: 140px;
}

.vms-camera-name-label{
  content:"";
  border-radius: 0px 5px 5px 0px;
  width: 5px;
}
.cameraU::after {
  @extend .vms-camera-name-label;
  background-color: var(--color-gray);
}

.cameraR::after {
  @extend .vms-camera-name-label;
  background-color: var(--color-danger);
}

.camera-border-left {
  border-left: 1px solid var(--color-half-dark-gray);
}

.vms-events-search-input-wrapper {
  top: 124px;
  z-index: 2;
  background: var(--color-dark-gray) !important;
}

