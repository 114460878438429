.settings-configuration {
  &__card {
    min-width: 200px;
  }

  &__error-section {
    min-height: 500px;
  };

  &__btns {
    width: 40%;
    min-width: 83px ;
  };
}