.map-layers-dropdown {
  width: 200px;

  &__control {
    background:  var(--color-half-dark-gray);
    border: 1px solid transparent;
    border-radius: 1rem;
    color: var(--color-lihgt-gray);
    cursor: pointer;
    font-size: 1rem;
  }

  &__menu {
    background: var(--color-half-dark-gray);
    border-radius: 0.5rem;
    border: none;
    padding: 1rem 0;
  }

  &__option {
    border-radius: 0.25rem;
    font-size: 1rem;
    color: var(--color-lihgt-gray);

    &:hover {
      background: var(--color-lihgt-gray);
      color: var(--color-dark-gray);
    }

    &.is-selected {
      background: var(--color-success);
      color: white;
    }
  }
}