@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink-animation {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  86% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

