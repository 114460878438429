.station {
  color: var(--color-medium-gray);

  &__filter {
    position: absolute;
    right: 0;
    top: 99px;
    z-index: 0;
  }
}
