$prefix: station-card;

.dashboard-station-card {
  height: 70px;

  //&__main {
  //  padding: 0 1rem 1rem;
  //}
  //
  //&__label {
  //  right: 0;
  //}
  //
  //&__edit-icon {
  //  width: 20px;
  //  height: 20px;
  //  margin-top: -3px;
  //}
  //
  //.dashed-border {
  //  border: 1px dotted var(--color-gray);
  //  border-radius: 0.5rem;
  //  padding: 0.25rem;
  //}
  //
  &__count-icon {
    width: 100px;
  }
  //
  //&.disabled {
  //  pointer-events: none;
  //  opacity: 0.3;
  //}

  &__counts-title {
    margin-bottom: 0.1rem;
  }
}


