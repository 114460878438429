$border-size: 0.2rem;
$size: 1.1rem;

.small-loader,
.small-loader:after {
  border-radius: 50%;
  width: $size;
  height: $size;
}
.small-loader {
  display: inline-block;
  position: relative;
  text-indent: -9999em;
  border-top: $border-size solid rgba(255, 255, 255, 0.2);
  border-right: $border-size solid rgba(255, 255, 255, 0.2);
  border-bottom: $border-size solid rgba(255, 255, 255, 0.2);
  border-left: $border-size solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
