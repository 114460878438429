.stations__station-card {
  padding-bottom: 0.75rem;

  &__btn:not(:last-child) {
    margin-right: 0.5rem;
  }

  &__grid-table-1 {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  &__grid-table-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
