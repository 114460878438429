@import "src/styles/mixins";

$max-height-auth: 30rem;
$max-width-auth: 1000px;

.auth {
  &__wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  &__container {
    height: 100%;
    max-height: $max-height-auth;
    padding-top: 4rem;
    padding-bottom: 4rem;
    max-width: $max-width-auth;
    margin: 0 auto;
    position: relative;
    width: 100%;

    &__layout {
      @include breakpoint-lg {
        left: 60% !important;
      }
    }

    &__layout {
      @include breakpoint-md {
        left: 70% !important;
      }
    }
  }

  &__logo {
    background-repeat: no-repeat;
    background-position: -300px 50%;
    background-size: auto 75%;
    border-radius: 1rem;
    height: 100%;
    text-align: center;
    left: 0;
    position: fixed;
  }

  &__localization-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__form {
    width: 100%;
  }
}

