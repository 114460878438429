.user-card {
  padding-bottom: 0.75rem;

  &__role {
    text-transform: uppercase;

    &.is-admin {
      color: var(--color-green);
    }
  }

  &__del-btn {
    align-self: baseline;
    margin-bottom: 1rem;
  }
}
