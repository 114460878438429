.dropdown-menu {
  position: fixed;
  z-index: 100;
}

.draw-instrument__item {
  border-radius: 0.5rem;
  box-shadow: 0 0 3px 1px var(--color-dark-gray);

  &.is-active {
    background: var(--color-success);
  }
}

.azimut-circle {
  &__circle {
    position: absolute;
    width: 400px;
    height: 400px;
    z-index: 10000;
  }
}
.map-info {

}
