.auth_form {
  &__container {
    width: 100%;
    //padding: 0 2rem;
  }

  &__input-wrapper {
    width: 100%;
    margin: 0 auto 1rem;
  }

  &__input {
    color: var(--color-white);
    display: block;
    width: 100%;
  }

  &__error-msg {
    font-size: 0.9rem;
    color: var(--color-danger);
  }
  &__sbm-btn {
    float: right;
  }
}

