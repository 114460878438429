.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: 0.25rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.bottom-3 {
  bottom: 1rem;
}

.bottom-4 {
  bottom: 1.5rem;
}

.bottom-5 {
  bottom: 3rem;
}

// **** RIGHT ****

.right-0 {
  right: 0;
}

.right-1 {
  right: 0.25rem;
}

.right-2 {
  right: 0.5rem;
}

.right-3 {
  right: 1rem;
}

.right-4 {
  right: 1.5rem;
}

.right-5 {
  right: 3rem;
}

// **** TOP ****

.top-0 {
  top: 0;
}

.top-1 {
  top: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.top-3 {
  top: 1rem;
}

.top-4 {
  top: 1.5rem;
}

.top-5 {
  top: 3rem;
}

// **** PADDING BOTTOM *****

.pb--05rem {
  padding-bottom: -0.5rem;
}

// **** LEFT ****

.left-0 {
  left: 0;
}

.left-1 {
  left: 0.25rem;
}

.left-2 {
  left: 0.5rem;
}

.left-3 {
  left: 1rem;
}

.left-4 {
  left: 1.5rem;
}

.left-5 {
  left: 3rem;
}

.h-100vh {
  height: 100vh;
}