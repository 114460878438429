@import "src/styles/mixins";

$border-radius: 0.25rem;
$bar_space: 20px;
$bar-width: 45px;
$height: 500px;

.spectrum {
  position: relative;
  height: 90% !important;
  width: 100%;
  overflow: visible;
  pointer-events: none;

  &__details {
    position: absolute;
    right: 0;
    width: 200px;
    margin-top: -20px;
    top: 0;
  }

  &__bar {
    position: absolute;
    width: 1px;
    height: 100px;
    bottom: 0;
    opacity: 1;

    &__item {
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);
      min-width: 1px;

      &.is-primary {
        background: var(--color-primary);
        outline: 1px solid black;
      }

      &.is-success {
        background: var(--color-success);
        outline: 1px solid black;
      }
    }

  }

  &__x-value {
    position: absolute;
    bottom: -5%;
    transform: translateX(-50%);

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 5px;
      background: var(--color-gray);
      left: 50%;
      top: -12px;
    }
  }

  &__zipper {
    right: 0;
  }

  &__visible-band {
    position: absolute;
    top: -2rem;
    right: 0;
  }

  &__x-border {
    position: absolute;
    width: 100%;
    bottom: 13.5%;
    background: (var(--color-gray));
    height: 1px;
  }
  &__zipper {
    position: absolute;
    bottom: -10%;
  }

  &__center-line {
    position: absolute;
    top: 0;
    height: 72%;
    width: 1px;
    border-left: 1px dashed var(--color-gray);
    left: 50%;
  }

  &__y-axis {
    height: calc(90% + 7px);
    border-right: 1px solid var(--color-gray);
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-right: 0.5rem;
    text-align: right;
    color: var(--color-gray);

    span {
      transform: translateY(-50%);
      position: relative;
      height: 10px;
      display: block;

      &:after {
        content: '-';
        position: absolute;
        display: block;
        width: 4px;
        height: 1px;
        right: -0.5rem;
        top: 0;
      }
    }
  }

  &__x-unit {
    position: absolute;
    bottom: 5%;
    right: 0;
    color: var(--color-gray);
  }

  &__y-unit {
    position: absolute;
    top: -7%;
    left: 0;
    color: var(--color-gray);
  }

  &__map {
    height: 50px;
    position: relative;
    //margin-bottom: 2rem;
  }

  &__zoom-label {
    opacity: 50%;
    left: 25% !important;
    top: -30px;
  }
}

.spectrum-map {
  width: 100%;
  height: 100px;
  cursor: pointer;
  border-radius: 4px;
  background: var(--color-dark-gray);

  &__switcher {
    position: absolute;
    height: 100%;
    background: var(--color-half-dark-gray);
    bottom: 0%;
    transform: translateX(-50%);
    //transform: translate(-5px, 10px);
  }
}
