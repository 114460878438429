$border-radius: 0.25rem;
$bg-color: var(--color-gray);
$transition-duration: 250ms;

.my-tabs-panel {
  &__tab {
    list-style: none;
    padding: 0.75rem;
    border-radius: $border-radius;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--color-white-medium-gray);
    font-size: 1.125rem;
    transition: all $transition-duration;

    &-selected {
      color: var(--color-success);
    }

    &:hover {
      color: var(--color-white);
    }
  }

  &__list {
    list-style: none;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 1rem;
    cursor: pointer;
    text-transform: capitalize;
    color: var(--color-white-medium-gray);
    font-size: 1rem;
    transition: all $transition-duration;
    border-bottom: 2px solid transparent;

    &-selected {
      background: var(--color-half-dark-gray);
      color: var(--color-success);
      border-bottom: 2px solid var(--color-success);
    }
  }

  &__panel {
    //background: var(--color-half-dark-gray);
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}
