.close-icon {
  color: var(--color-lihgt-gray);
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  border-radius: 50%;
  transition: all 150ms;
  padding: 0.25rem;
  cursor: pointer;

  &:hover {
    color: var(--color-white);
  }
}
